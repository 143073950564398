import React, { useEffect, useLayoutEffect, useState } from 'react';
import FormHeading from './FormHeading';
import { HeaderBoarder, PopupModel } from '../Components';
import { usePostDataApi, useregFormValidation } from '../CostomHooks';
import SearchDropDown from "../Forms.js/Criterial.js"
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import { get_country, get_state, get_city, post_member, update_member } from '../ApiConfig.js';
import { updateMember } from '../Redux/authSlicer.js';
import { useForm } from 'react-hook-form';
import { CheackBox, Input, InputDate, PhotoUpload, SelectInput, Textarea, SearchDropDownCriteria, CityDropDown } from '../FormsInputes.js'
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import GodLogo from "../assets/login card/bhagwanlogo.png"
import { showSuccessAlert } from '../Components/ShowAlertCustome.js';
import LoadingModal from './LoadingModel.js';
import { gotra } from "../constants/staticData.js"
import AvatarEditorCom from './AvatarEditor.js';


// import axios from 'axios';

// constant data for form
const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const initialFormData = {
    profilePhoto: "",
    membershipCode: 'Not Actived',
    bloodGroup: 'A+',
    name: '',
    email: '',
    surname: '',
    mobileNo: '',
    whatsappNo: '',
    fatherName: '',
    motherName: '',
    gotra: '',
    showMobileNo: true,
    gender: "Male",
    birthDate: '',
    permanentAddress: '',
    currentAddress: '',
    city: '',
    country: '',
    State: '',
    pincode: '',
    currentAddressArea: '',
    occupation: '',
    educationQualification: '',
    memberFamilyDetails: [{
        memberName: '',
        age: '',
        dob: '',
        gender: 'Male',
        relation: '',
        maritalStatus: ''
    }]

};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based, so we add 1
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Format the date as dd/mm/yyyy
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
};



const RegistrationForm = () => {
    const user = useSelector(state => state?.auth?.user);
    const authData = useSelector(state => state.auth);
    const formData = user ? user : initialFormData;

    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({ ...formData });
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setloading] = useState(false)
    const [avatar, setEditedAvatar] = useState("")
    const [state, setState] = useState([])
    const [country, setCountry] = useState([])
    const [city, setCity] = useState([formData?.city])
    const [termAndConditions, setTermAndConditions] = useState(false)
    const dispatch = useDispatch()
    const [value, setValueLocation] = useState([]);
    const [reCapcha, setreCapchToken] = useState("");
    const [isMobileNuShow, setIsMobileNuShow] = useState(formData.showMobileNo && true)
    const [cityOptions, setCityOptions] = useState([]);

    const navigater = useNavigate();


    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    const handelTermsAndConditions = (e) => {
        console.log(e.target.checked)

        if (e.target.checked) {
            setIsOpen(true)
            setTermAndConditions(true)
        } else {
            setIsOpen(false)
            setTermAndConditions(false)
        }

        // Toggle the checked state
        // setIsChecked(!termAndConditions);
        // Call the togglePopup function
    };

    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };

    // const { data, loading, error, postData } = usePostDataApi();

    console.log(formData)

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;


    //     setFormData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const handleFormData = (data, prefix = '', uploadForm = new FormData()) => {

        for (const key in data) {

            if (Array.isArray(data[key])) {
                continue
                // If the value is an array, iterate through each item and handle objects recursively
                data[key].forEach((item, index) => {
                    handleFormData(item, `${prefix}${key}[${index}].`, uploadForm);
                });
            } else if (typeof data[key] === 'object' && data[key] instanceof File) {
                // If the value is a File object, append it to FormData
                continue
                uploadForm.append(`${prefix}${key}`, data[key]);
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                continue
                // If the value is an object (not an array or file), recursively call handleFormData
                handleFormData(data[key], `${prefix}${key}.`, uploadForm);
            } else {
                // Otherwise, append the key-value pair to FormData
                uploadForm.append(`${prefix}${key}`, data[key]);
            }
        }

        // for img option tep close
        // if (data.profilePhoto instanceof File) {
        //     uploadForm.append('profilePhoto', data.profilePhoto);
        // }
        return uploadForm;
    };
    // const handleFromSubmit = async (data) => {
    //     console.log(data)
    // }
    const handlereCapchaToken = (e) => {
        const { name, value } = e.target;
        console.log("reChapch", value)
        setreCapchToken(value);
    };

    const handleFromSubmit = async (data) => {

        console.log(data)
        if (!termAndConditions) {
            Swal.fire({
                title: 'Please read terms and conditions',
                text: 'Please read terms and conditions',
                icon: 'warning',
            });
            return;
        }
        if (!avatar) {
            Swal.fire({
                title: 'Please add Photo',
                text: 'Please add Photo',
                icon: 'warning',
            });
            return;
        }
        const uploadForm = handleFormData(data);
        console.log(data)
        delete uploadForm?.memberFamilyDetails
        uploadForm.append('city', JSON.stringify(city?.city));
        uploadForm.append('memberFamilyDetails', JSON.stringify(data?.memberFamilyDetails || []));
        // uploadForm.append('membershipCode', 'Not Actived');
        uploadForm.append('profilePhoto', avatar);
        try {
            setloading(true)
            const options = {
                'Content-Type': 'multipart/form-data',
                body: uploadForm
            }
            const res = await axios.post(`${post_member}`, uploadForm, options);
            console.log('res', res)
            if (res?.data?.success) {
                Swal.fire({
                    title: res?.data?.message,
                    text: res?.data?.message,
                    icon: 'success',
                });
                // reset()
                setloading(false)
                navigater('/')
            }

        } catch (error) {
            console.error('Registration failed', error?.response?.data?.message);
            Swal.fire({
                title: error?.response?.data?.message,
                text: error?.response?.data?.message,
                icon: 'error',
            });
            setloading(false)
        } finally {
            setloading(false)
        }
    };

    useLayoutEffect(() => {
        (async () => {

            const res = await axios(`${get_country}`)
            console.log(res?.data?.data);
            setCountry(pre => res?.data?.data)
        }
        )()
    }, []);

    const handleCheckboxChange = (item, checked) => {
        setValue(item, checked)
        setIsMobileNuShow(checked)
    };

    const handleFromEditSubmit = async (data) => {
        try {
            // Create form data
            setloading(true)
            const uploadForm = handleFormData(data);
            delete uploadForm?.memberFamilyDetails
            delete uploadForm.mobileNo
            uploadForm.append('memberFamilyDetails', JSON.stringify(data?.memberFamilyDetails || []));
            uploadForm.append('profilePhoto', avatar);
            uploadForm.append('city', JSON.stringify(city?.city));
            const response = await dispatch(updateMember(uploadForm))
            if (response.error) {
                Swal.fire({
                    title: response.error.message,
                    text: response.error.message,
                    icon: 'error',
                });
                setloading(false)
                return // Inform user of specific error
            } else if (response.payload.success) {
                showSuccessAlert(response.payload.message, GodLogo)
                setloading(false)
                navigater('/')
            }

        } catch (error) {
            // Handle error
            console.error('Error updating member:', error);
            Swal.fire({
                title: error.response?.data.message,
                text: error.response?.data.message,
                icon: 'error',
            });
            setloading(false)
        } finally {
            setloading(false)
        }
    }

    const onClear = () => {


    }


    return (
        <>
            <TermsAndConditions isOpen={isOpen} setIsOpen={setIsOpen} />
            <LoadingModal show={isLoading} text={'Sending...'} />
            <div className=' my-5 over'>
                <HeaderBoarder heading={`${authData?.isLogin ? 'एडिट मेंबर डिटेल ' : 'मेंबर रेजिस्टशन'}`} />
            </div>
            <div className='w-100  md:px-10 '>
                <form onSubmit={handleSubmit(authData.isLogin ? handleFromEditSubmit : handleFromSubmit)} className='border-[1px] border-gray-900/10 p-4'>
                    <div className="space-y-12 border-[1px]  border-gray-900/10 ">
                        <div className=" border-gray-900/10 pb-2">
                            <FormHeading heading={'Personal Information'} />
                            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">

                                {/* name */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Name / नाम"
                                        placeholder="Name / नाम"
                                        type="text"
                                        required="required"

                                        {...register("name", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.name}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("name", e)}
                                    />
                                    {errors.name && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.name.message}</p>}
                                </div>
                                {/* surName */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Surname / उपनाम"
                                        placeholder="Surname  / उपनाम"
                                        type="text"
                                        required="required"
                                        {...register("surname", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.surname}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "कृपया 20 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("surname", e)}
                                    />
                                    {errors.surname && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.surname.message}</p>}
                                </div>
                                {/* mobile number */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Mobile no / मोबाइल नंबर"
                                        placeholder="सदस्य का मोबाइल नंबर"
                                        type="text"
                                        required="required"
                                        {...register("mobileNo", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",

                                            value: `${formData?.mobileNo}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("mobileNo", e)}
                                        disabled={formData?.mobileNo ? true : false}
                                    />
                                    {errors.mobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.mobileNo.message}</p>}
                                </div>

                                {/* whatsappNo */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Whatsapp No / व्हाट्सएप नंबर"
                                        placeholder="व्हाट्सएप मोबाइल नंबर"
                                        type="text"
                                        required="required"
                                        {...register("whatsappNo", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.whatsappNo}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("whatsappNo", e)}
                                    />
                                    {errors.whatsappNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.whatsappNo.message}</p>}
                                </div>
                                {/* fatherName */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Father's Name / पिताजी नाम"
                                        placeholder=" पिताजी का नाम"
                                        type="text"
                                        required="required"

                                        {...register("fatherName", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.fatherName}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 100 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("fatherName", e)}
                                    />
                                    {errors.fatherName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherName.message}</p>}
                                </div>

                                {/* motherName */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Mother's Name / माताजी का नाम"
                                        placeholder="माताजी का नाम"
                                        type="text"
                                        required="required"

                                        {...register("motherName", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.motherName}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 100 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("motherName", e)}
                                    />
                                    {errors.motherName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.motherName.message}</p>}
                                </div>

                                {/* gotra */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Gotra / गोत्र"
                                        placeholder="गोत्र"
                                        options={gotra}
                                        required="required"
                                        {...register("gotra", {
                                            value: `${formData?.gotra}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("gotra", e)}
                                    />
                                    {errors.gotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gotra.message}</p>}
                                </div>

                                {/* birthDate */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Birth Date / जन्म तिथि"
                                        placeholder="जन्म तिथि"
                                        type="date"
                                        required="required"

                                        {...register("birthDate", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData.birthDate?.split('T')[0]}`,
                                        })}
                                    />
                                    {errors.birthDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.birthDate.message}</p>}
                                </div>

                                {/* email */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Email id / ईमेल आईडी"
                                        placeholder="ईमेल आईडी"
                                        type="email" // Change type to "email" for email validation
                                        // required="required"
                                        {...register("email", {

                                            value: `${formData?.email || ""}`,

                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regular expression for email validation
                                                message: "कृपया मान्य ईमेल पता दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("email", e)}
                                    />
                                    {errors.email && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                                </div>

                                {/* Gender */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Gender"
                                        placeholder="Gender"
                                        options={["Male", "Female"]}
                                        required="required"
                                        {...register("gender")}
                                        onChange={(e) => handleChange("gender", e)}
                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                    />
                                    {errors.gender && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gender.message}</p>}
                                </div>
                                {/* bloodGroup */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label=" Blood Group / ब्लड ग्रुप "
                                        options={bloodGroups}
                                        {...register("bloodGroup", {
                                            value: formData?.bloodGroup, // Default value for form registration
                                        })}
                                        onChange={(e) => handleChange("bloodGroup", e)}
                                    />
                                    {errors.bloodGroup && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.bloodGroup.message}</p>}
                                </div>
                                {/* showMobileNum */}
                                <div className="sm:col-span-3 mt-auto me-auto ">
                                    <CheackBox
                                        label={'Mobile Number Public'}
                                        placeholder={'Mobile Number Public'}
                                        name="reciveMethod"
                                        type="checkbox"
                                        // value={formData?.showMobileNo || true}
                                        checked={isMobileNuShow}
                                        onChange={(e) => handleCheckboxChange("showMobileNo", e.target.checked)}
                                    />
                                </div>
                            </div>

                            <FormHeading heading={'Address'} />
                            {/* permanentAddress */}
                            <div className=" border-gray-900/10 pb-2">
                                <div className="col-span-full">
                                    <Textarea
                                        label="Permanent Address/ स्थाई पता"
                                        placeholder="स्थाई पता"
                                        type="text"
                                        required="required"

                                        {...register("permanentAddress", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.permanentAddress}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 20 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("permanentAddress", e)}
                                    />
                                    {errors.permanentAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.permanentAddress.message}</p>}

                                </div>

                                {/* currentAddress */}
                                <div className="col-span-full">
                                    <Textarea
                                        label="Current Address / वर्तमान पता"
                                        placeholder="वर्तमान पता"
                                        type="text"
                                        required="required"
                                        {...register("currentAddress", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.currentAddress}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 100 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("currentAddress", e)}
                                    />
                                    {errors.currentAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentAddress.message}</p>}
                                </div>
                                {/* currentAddressArea */}
                                <div className="col-span-full">
                                    <Textarea
                                        label="Current Address Area / पता एरिया"
                                        placeholder="पता एरिया"
                                        type="text"
                                        required="required"
                                        {...register("currentAddressArea", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.currentAddressArea}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 20 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("currentAddressArea", e)}
                                    />
                                    {errors.currentAddressArea && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentAddressArea.message}</p>}

                                </div>
                                <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5'>



                                    {/* CityDropDow */}
                                    <div className="sm:col-span-3">
                                        <CityDropDown
                                            options={cityOptions}
                                            setCity={setCityOptions}
                                            search={'city'}
                                            id={'currentCity'}
                                            setOptions={setCity}
                                            alredySetCity={formData?.city}
                                            required="required"
                                            label={"City / शहर"}
                                            className={' w-full h-9 px-4 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                            style={' text-black font-bold'}
                                        />
                                    </div>

                                    {/* pincode */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="Pincode / पिनकोड"
                                            placeholder="पिनकोड"
                                            type="text"
                                            required="required"
                                            {...register("pincode", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.pincode}`,
                                                minLength: {
                                                    value: 6,
                                                    message: "कृपया कम से कम 6 अंक भरें"
                                                },
                                                maxLength: {
                                                    value: 6,
                                                    message: "कृपया 6 अंक से अधिक न भरें"
                                                },
                                                pattern: {
                                                    value: /^[0-9]\d{5}$/, // Regular expression to match Indian mobile numbers
                                                    message: "कृपया मान्य पिनकोड नंबर दर्ज करें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("pincode", e)}
                                        />
                                        {errors.pincode && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.pincode.message}</p>}
                                    </div>
                                    {/* <div className="w-100 md:w-1/3">
                                    <label htmlFor="pincode" className="block required text-sm font-medium leading-5 text-gray-900">
                                        pincode
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            value={formData.pincode} onChange={handleInputChange}
                                            type="number"
                                            name="pincode"
                                            id="Mobile no "
                                            placeholder='pincode'
                                            className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                    {errors.pincode && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.pincode}</p>}
                                </div> */}
                                </div>
                            </div>
                            <FormHeading heading={'Occupation & Education'} />
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">

                                {/* occupation */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Occupation / व्यवसाय"
                                        placeholder="Occupation"
                                        type="text"
                                        required="required"

                                        {...register("occupation", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.occupation}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 100 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("occupation", e)}
                                    />
                                    {errors.occupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.occupation.message}</p>}
                                </div>

                                {/* educationQualification */}

                                <div className="sm:col-span-3">
                                    <Input
                                        label="Education Qualification / शैक्षणिक योग्यता"
                                        placeholder="शैक्षणिक योग्यता"
                                        type="text"
                                        required="required"

                                        {...register("educationQualification", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.educationQualification}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 200 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("educationQualification", e)}
                                    />
                                    {errors.educationQualification && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.educationQualification.message}</p>}
                                </div>

                                {/* <div className="sm:col-span-3">
                                <label htmlFor="Education_Qualification  " className="block required text-sm font-medium leading-6 text-gray-900">
                                    Education Qualification
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={formData.educationQualification} onChange={handleInputChange}
                                        type="text"
                                        name="educationQualification"
                                        id="Education_Qualification "
                                        placeholder='Education Qualification '
                                        required
                                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                {errors.educationQualification && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.occupation}</p>}

                            </div> */}
                            </div>
                        </div>
                        <FormHeading heading={'Family Detail'} />

                        <FamilyDetails members={formData?.memberFamilyDetails} setValue={setValue} />
                        {/* PhotoUpload */}
                        <FormHeading heading={'Profile Photo'} />
                        <div className=''>
                            <AvatarEditorCom setEditedAvatar={setEditedAvatar} alreadySetAvatar={formData?.profilePhoto} />
                        </div>
                        {/* <ReCAPTCHA
                            sitekey={`6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI`}
                            onChange={handlereCapchaToken}
                        /> */}
                        {!authData.isLogin && <div className="mt-6 space-y-6 w-full text-center flex justify-center">
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        // checked={isChecked}
                                        onClick={(e) => handelTermsAndConditions(e)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="comments" className="font-medium text-gray-900">
                                        I agree to the terms and conditions
                                    </label>
                                </div>
                            </div>
                        </div>}
                    </div>

                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <Link to={'/'} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </Link>
                        {!authData.isLogin ? <button
                            disabled={!termAndConditions}
                            type="submit"
                            className={`rounded-md  ${!termAndConditions ? 'bg-gray-400' : 'bg-indigo-600'} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                        >
                            Save
                        </button> : <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Update
                        </button>}
                    </div>
                </form>
            </div>
        </>
    );
};
export default RegistrationForm;




const FamilyDetails = ({ members, setValue }) => {

    const [familyMembers, setFamilyMembers] = useState(members || [])

    console.log(members.maritalStatus)

    const relations = [
        { value: "wife", label: "Wife" },
        { value: "mother", label: "Mother" },
        { value: "father", label: "Father" },
        { value: "son", label: "Son" },
        { value: "daughter", label: "Daughter" },
        { value: "brother", label: "Brother" },
        { value: "sister", label: "Sister" },
        { value: "brotherswife", label: "Brother's Wife" },
        { value: "grandmother", label: "Grandmother" },
        { value: "grandfather", label: "Grandfather" },
        { value: "other", label: "Other" }
    ];
    const handleAddMember = () => {
        const newMember = {
            memberName: '',
            age: '',
            dob: '',
            gender: 'Male',
            relation: '',
            maritalStatus: ''
        };
        setFamilyMembers(prev => [...prev, newMember]);
    };

    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setFamilyMembers(prevState => {
            const updatedFamilyMembers = [...prevState];
            updatedFamilyMembers[index] = { ...updatedFamilyMembers[index], [name]: value };
            return updatedFamilyMembers;
        });

        console.log("familyMembers", familyMembers)
        setValue("memberFamilyDetails", familyMembers)
    };


    const deleteMember = (index) => {
        const updatedMembers = familyMembers.filter((member, idx) => idx !== index);
        // setFamilyMembers(prevState => ({ ...prevState, memberFamilyDetails: updatedMembers }));
        setFamilyMembers(updatedMembers)
    };

    return (
        <>
            {familyMembers?.map((member, index) => (
                <div key={index}>
                    <p onClick={() => deleteMember(index)} className="mx-5 cursor-pointer">&#128465; Delete</p>
                    <div key={index} className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-2 md:mx-5">
                        <div className="sm:col-span-3">
                            <label htmlFor={`memberName_${index}`} className="block required text-sm font-extrabold leading-6 text-gray-900">
                                Member Name
                            </label>
                            <div className="mt-1">
                                <input
                                    value={member.memberName}
                                    onChange={(e) => handleInputChange(index, e)}
                                    type="text"
                                    name={`memberName`}
                                    id={`memberName`}
                                    placeholder="Member Name"
                                    className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`age_${index}`} className="block required text-sm font-extrabold leading-6 text-gray-900">
                                Age
                            </label>
                            <div className="mt-1">
                                <input
                                    value={member.age}
                                    onChange={(e) => handleInputChange(index, e)}
                                    type="number"
                                    name={`age`}
                                    id={`age_${index}`}
                                    placeholder="Age"
                                    className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`dob_${index}`} className="block required text-sm font-extrabold leading-6 text-gray-900">
                                DOB
                            </label>
                            <div className="mt-1">
                                <input
                                    value={member.dob}
                                    onChange={(e) => handleInputChange(index, e)}
                                    type="date"
                                    name="dob"
                                    id={`dob_${index}`}
                                    placeholder="Date of Bird"
                                    className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>


                        <div className="sm:col-span-3">
                            <label htmlFor={`gender_${index}`} className="block required text-sm font-extrabold leading-6 text-gray-900">
                                Gender
                            </label>
                            <div className="mt-1">
                                <select
                                    value={member.gender} onChange={(e) => handleInputChange(index, e)}
                                    id={`gender_${index}`}
                                    name="gender"
                                    autoComplete="gender"
                                    className="block px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor={`relation_${index}`} className="block required text-sm font-extrabold leading-6 text-gray-900">
                                Relation
                            </label>
                            <div className="mt-1">
                                <select
                                    value={member.relation} onChange={(e) => handleInputChange(index, e)}
                                    id={`relation_${index}`}
                                    name="relation"
                                    autoComplete="relation"
                                    className="block px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="">Select Relation</option>

                                    {relations.map((relation, index) => {
                                        return <option key={relation.value} value={relation.value}>{relation.label}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`marital_status_${index}`} className="block required text-sm font-extrabold leading-6 text-gray-900">
                                Marital Status
                            </label>
                            <div className="mt-1">
                                <select
                                    value={member.maritalStatus}
                                    onChange={(e) => handleInputChange(index, e)}
                                    id={`marital_status_${index}`}
                                    name="maritalStatus"
                                    autoComplete="marital_status"
                                    className="block px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="">Select Relation</option>
                                    <option value='Married'>Married</option>
                                    <option value='Single'>Single</option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Widowed">Widowed</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <button type='button' className='btn' onClick={handleAddMember}>Add More +</button>
        </>
    );
};

const TermsAndConditions = ({ isOpen, setIsOpen, }) => {

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Add event listener when component mounts
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
        }

        // Remove event listener when component unmounts
        return () => {
            document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className="fixed z-[999999999] top-0 left-0 flex flex-col items-center justify-start w-screen h-screen overflow-hidden bg-black bg-opacity-50">
                    <div className='w-full    p-4 bg-white shadow-2xl overflow-y-auto'>

                        <div className=' bg-blue-950 text-white text-center text-2xl'> Terms and Conditions</div>
                        <div className="content p-5">
                            {termsAndConditions.map((content, index) => (
                                <div className=' my-2' key={index}>
                                    <h1 className=' text-xl text-black  font-bold'>{content.heading}</h1>
                                    <p className=' text-gray-600'>{content.content}</p>
                                </div>
                            ))}
                            {/* Add more content here */}
                            <div className=' w-full text-center'>
                                <button className=' cursor-pointer px-5 py-2 text-xl font-bold bg-blue-950 text-white text-center rounded-3xl' onClick={togglePopup}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>





            )}
        </>
    );
};

const termsAndConditions = [
    {
        heading: "Introduction",
        content: "merasamaj.com is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. merasamaj.com may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 15th may’23."
    },
    {
        heading: "What we collect",
        content: "We may collect the following information: name and job title contact information including email address demographic information such as postcode, preferences and interests other information relevant to customer surveys and/or offers"
    },
    {
        heading: "What we do with the information we gather",
        content:
            " We require this information to understand your needs and provide you with a better service, and in particular for the following reasons         Internal record keeping We may use the information to improve our products and services.We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.From time to time, we may also use your information to contact you for market research purposes.We may contact you by email, phone, fax or mail.We may use the information to customise the website according to your interests."
    },
    {
        heading: "Security",
        content: "We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online."
    },
    {
        heading: "How we use cookies",
        content: "A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website."
    },
    {
        heading: "Links to other websites",
        content: "Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question."
    },
    {
        heading: "Controlling your personal information",
        content: "You may choose to restrict the collection or use of your personal information in the following ways: whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes. if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@merasamaj.com. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen. You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to info@merasamaj.com. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible at the above address. We will promptly correct any information found to be incorrect."
    },

]










