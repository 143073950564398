// ImageCarousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Ads1 from "../assets/ads1.png"
import Ads2 from "../assets/ads2.png"
// import './ImageCarousel.css';  // Custom styles for the carousel

const ImageCarousel = () => {

    const images = [
        { src: Ads1, alt: 'Slide 1', caption: 'Order Now' },
        { src: Ads2, alt: 'Slide 2', caption: 'Book Now' },
        // { src: 'https://my-online-shop.netlify.app/static/media/slider1.c8bfec124194f77c135c.jpg', alt: 'Slide 3', caption: 'Slide 3 Caption' },
    ];

    const customPrevArrow = (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="absolute left-5 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none z-10"
            >
                &larr;
            </button>
        );

    const customNextArrow = (onClickHandler, hasNext, label) =>
        hasNext && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none z-10"
            >
                &rarr;
            </button>
        );
    return (
        <div className="carousel-container">
            <Carousel
                showArrows={true}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                className="carousel"
                renderArrowPrev={customPrevArrow}
                renderArrowNext={customNextArrow}
            >
                {images.map((image, index) => (
                    <div className='h-[120px] sm:h-[300px] ' key={index}>
                        <img className=' h-[120px] sm:h-[300px]' src={image.src} alt={image.alt} />

                        <div className="absolute bottom-5 w-full flex justify-center">
                            <p className=" cursor-pointer font-bold w-30 bg-blue-600 text-white py-1 px-4 rounded-md shadow-md">{image.caption}</p>
                        </div>


                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ImageCarousel;
