import React, { useEffect } from 'react';
import GodLogo from "../assets/final cards for mayur/committee cards/samajbutton.png"
import { Domain } from '../ApiConfig';
import callporwal from "../assets/membercard/callbutton.png"
import chatporwal from "../assets/membercard/chatbutton.png"
import report from "../assets/membercard/reportbutton.png"
import QR from "../assets/membercard/qr.png"
import { FormHeading } from '../Forms';

const PopupExample = ({ isOpen, setIsOpen, popupData, }) => {

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Add event listener when component mounts
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
        }
        // Remove event listener when component unmounts
        return () => {
            document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className="fixed z-[99999999] pt-[47rem]  sm:pt[22rem]  flex justify-center items-center  inset-0 overflow-y-auto overflow-x-hidden w-[100vw] h-[100%]  bg-black bg-opacity-50 ">
                    <div className=' w-[95%] mb-16    p-4 comittee-card-bg-color-bottom shadow-2xl'>
                        <span onClick={togglePopup} className=' w-full text-2xl text-white font-semibold cursor-pointer flex justify-end hover:text-yellow-400'>&#10005;</span>
                        <div className=' w-full flex justify-between'>
                            <img className=" h-10 w-10  rounded-full top-5 border-2  bg-gray-50" src={GodLogo} alt="GodLogo" loading="lazy" />
                            <h1 className=" text-yellow-400 font-bold  ">श्री जांगड़ा पोरवाल पंचायती सभा इंदौर</h1>
                        </div>
                        <div className=' relative w-full flex flex-col items-center  h-20 rounded-t-xl'>
                            {/* <h1 className=" text-orange-500  ">{popupData.name}</h1> */}
                            <h1 className=" text-white  font-bold  "> Member code :{popupData.membershipCode}</h1>
                            <img className=" h-20 w-20 absolute left-1/3 top-1/2 md:left-[45%]  rounded-full  z-[99999]   border-4  bg-gray-50" src={`${Domain + popupData?.profilePhoto?.replace(" ", "%20")}`} alt="Profile Photo" loading="lazy" />
                        </div>
                        <div className=' mt-10'>
                            <p className=' text-center  text-white font-bold rounded-md '>{popupData.name}</p>
                        </div>
                        {/* <div className=' flex flex-col my-3 items-center   justify-start    text-[12px] font-bold text-white'> */}

                        {/* <div className=' flex flex-col   justify-start    text-[14px] font-bold text-black'> */}
                        <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Personal Information'} />

                        <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Membership Code / मेंबरशिप कोड:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.membershipCode}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Name / नाम:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.name}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Surname / उपनाम:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.surname}</p>
                            </div>
                            {popupData?.mobileNo && (
                                <>
                                    <div className=''>
                                        <p className='text-start text-[15px] font-extrabold text-black  '>Mobile no / मोबाइल नंबर:</p>
                                        <p className='font-semibold text-[12px] text-blue-600'>{popupData.mobileNo}</p>
                                    </div>
                                    <div className=''>
                                        <p className='text-start text-[15px] font-extrabold text-black  '>Whatsapp No / व्हाट्सएप नंबर:</p>
                                        <p className='font-semibold text-[12px] text-blue-600'>{popupData.whatsappNo}</p>
                                    </div>
                                </>
                            )}
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Father’s Name / पिता का नाम:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.fatherName}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Mother’s Name / माताजी का नाम:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.motherName}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Gotra / गोत्र:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.gotra}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Birth Date / जन्म तिथि:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.birthDate}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Email id / ईमेल आईडी:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.email}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Gender:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.gender}</p>
                            </div>
                            <div className=''>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Blood Group / ब्लड ग्रुप:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.bloodGroup}</p>
                            </div>
                        </div>

                        <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Address'} />
                        <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                            <div className=' '>
                                <p className='text-start text-[15px] font-extrabold text-black  ' >Permanent Address / स्थाई पता: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.permanentAddress}</p>
                            </div>

                            <div className='   '>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Current Address / वर्तमान पता: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.currentAddress}</p>
                            </div>
                            <div className='  '>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Current Address Area / पता एरिया:</p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.currentAddressArea
                                }</p>
                            </div>

                            <div className='   '>
                                <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>City name / शहर का नाम: </p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{popupData.city.name}</p>
                                </div>
                            </div>
                            <div className='   '>
                                <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>State name / स्टेट का नाम: </p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{popupData.State.name}</p>
                                </div>
                            </div>
                            <div className='   '>
                                <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>Country name / देश का नाम: </p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{popupData.country.name}</p>
                                </div>
                            </div>
                            <div className=' '>  <p className='text-start text-[15px] font-extrabold text-black  '>Pincode / पिनकोड: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.pincode}</p>
                            </div>
                        </div>


                        <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Occupation & Education'} />
                        <div className='  grid grid-cols-1 rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                            <div className=' '>
                                <p className='text-start text-[15px] font-extrabold text-black  '>Education Qualification / शैक्षणिक योग्यता: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.educationQualification}</p>
                            </div>
                            <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black  '>Occupation / व्यवसाय: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{popupData.occupation}</p>
                            </div>
                        </div>
                        <MemberTable memberFamilyDetails={popupData?.memberFamilyDetails} />

                        <div className='flex justify-center my-5 items-baseline  '>
                            <div className='member-card-bg-bottom flex justify-center  gap-4 px-6 py-2  rounded-full shadow-2xl    items-end '>
                                <img className='w-8 h-8' src={QR} alt="QR Code" />
                                {popupData?.mobileNo ? <>
                                    <img onClick={() => window.location.href = `tel:${popupData.mobileNo}`} className='w-8 h-8' src={callporwal} alt="Call" loading="lazy" />
                                    <img onClick={() => window.location.href = `whatsapp://send?phone=${popupData.whatsappNo}`} className='w-8 h-8' src={chatporwal} alt="Chat" loading="lazy" />
                                </> :
                                    <>
                                        <img className='w-8 h-8' src={callporwal} alt="Call" loading="lazy" />
                                        <img className='w-8 h-8' src={chatporwal} alt="Chat" loading="lazy" />
                                    </>
                                }
                                {/* <img className='w-8 h-8' src={viewporwal} alt="View" loading="lazy" /> */}
                                <img className='w-8 h-8' src={report} alt="View" loading="lazy" />
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <button onClick={togglePopup} className=' p-2 px-5 cursor-pointer bg-blue-950 text-white rounded-3xl'>Close</button>
                        </div>
                    </div>

                </div>
            )}
        </>
    );
};

export default PopupExample;

const FormField = ({ label, value }) => {
    return (
        <div className=' '>
            <p className="text-md text-white font-extrabold font-serif ">{label}</p>
            <p className=" font-bold text-lg text-yellow-400">{value}</p>
        </div>
    );
};

export const MemberTable = ({ memberFamilyDetails }) => {
    //     const date = new Date("2024-04-03T00:00:00.000Z");
    // const options = {
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    //   timeZone: "Asia/Kolkata" // Set the time zone to Indian Standard Time (IST)
    // };



    if (memberFamilyDetails.length === 0 || !memberFamilyDetails[0]?.memberName) {
        return <></>;
    }

    return <>
        <div class="overflow-x-auto w-[100%] sm:w-[100%] px-5">
            <table class="table-auto min-w-full border-collapse shadow-2xl my-5">
                <thead class="bg-[#13174F]">
                    <tr class="text-white">
                        <th class="px-4 py-2">Sr No</th>
                        <th class="px-4 py-2">Name</th>
                        <th class="px-4 py-2">Age</th>
                        <th class="px-4 py-2">Gender</th>
                        <th class="px-4 py-2">Marital Status</th>
                        <th class="px-4 py-2">Relation</th>
                        <th class="px-4 py-2">DOB</th>
                    </tr>
                </thead>
                <tbody>
                    {memberFamilyDetails.map((member, index) => (
                        <tr class="bg-white hover:bg-gray-100" key={index}>
                            <td class="border px-4 py-2">{index + 1}</td>
                            <td class="border px-4 py-2">{member.memberName}</td>
                            <td class="border px-4 py-2">{member.age}</td>
                            <td class="border px-4 py-2">{member.gender}</td>
                            <td class="border px-4 py-2">{member.maritalStatus}</td>
                            <td class="border px-4 py-2">{member.relation}</td>
                            <td class="border px-4 py-2">{new Date(member.dob).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>


    </>
}

{/* <div className='flex flex-col items-center w-full'>
    <div className='text-black text-3xl rounded-t-xl mt-[-0.5px] px-4 py-1 text-center font-bold'>
        {popupData.member_name}
    </div>
    <div className="flex flex-col items-center justify-center w-full p-4">
        <div className="flex flex-col items-center gap-y-2">
            <img className="h-32 w-32 rounded-full bg-gray-50" src={vishalprofile} alt="" loading="lazy" />
            <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600">{popupData.department}</p>
            <p className="text-sm font-bold tracking-tight ">{popupData.member_post}</p>
        </div>
        </div>

        <img src={popupCandidetImg} alt="Candidate" />

        <div className='flex max-w-80 justify-between gap-2 w-full mt-5'>
            <button className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `tel:${9827033232}`}>
                <img className='h-10' src={callporwal} alt="Call" loading="lazy" />
                <span className='text-sm leading-7 font-bold tracking-tight '>Call</span>
            </button>
            <button className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}>
                <img className='h-10' src={chatporwal} alt="Chat" loading="lazy" />
                <span className='text-sm leading-7 font-bold tracking-tight '>Chat</span>
            </button>
            <button className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}>
                <img className='h-10' src={messageporwal} alt="Message" loading="lazy" />
                <span className='text-sm leading-7 font-bold tracking-tight '>Message</span>
            </button>

        </div>
    </div>
</div> */}
