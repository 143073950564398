import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import GodLogo from "../assets/login card/bhagwanlogo.png"

const MySwal = withReactContent(Swal);

const showSuccessAlert = (message, logo) => {
    MySwal.fire({
        html: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <img src={logo || GodLogo} alt="Logo" style={{ width: '100px', marginBottom: '20px' }} />
                <div style={{ fontSize: '3em', color: 'green', marginBottom: '20px' }}>✔️</div>
                <p>{message}</p>
            </div>
        ),
        showConfirmButton: true, // Show confirm button if needed
        customClass: {
            popup: 'my-custom-popup', // Custom class for further styling if needed
        },
    });
};

const showErrorAlert = (message, logo) => {
    MySwal.fire({
        html: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <img src={logo || GodLogo} alt="Logo" style={{ width: '100px', marginBottom: '20px' }} />
                <div style={{ fontSize: '3em', color: 'green', marginBottom: '20px' }}>❌</div>
                <p>{message}</p>
            </div>
        ),
        showConfirmButton: true, // Show confirm button if needed
        customClass: {
            popup: 'my-custom-popup', // Custom class for further styling if needed
        },
    });
};

const showImgInSwal = (text, img) => {
    MySwal.fire({
        html: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <img src={img} alt="Logo" style={{ width: '200px', marginBottom: '20px' }} loading='lazy' />
                {/* <div style={{ fontSize: '3em', color: 'green', marginBottom: '20px' }}>✔️</div> */}
                <p>{text}</p>
            </div>
        ),
        showConfirmButton: true, // Show confirm button if needed
        customClass: {
            popup: 'my-custom-popup', // Custom class for further styling if needed
        },
    });
}
export {
    showSuccessAlert,
    showErrorAlert,
    showImgInSwal
}