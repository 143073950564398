import React from 'react'
import { AdminButtons, DharmashalaBookingFrom, } from "../../../Forms"
import PulicButtons from '../../../Forms/PublicButtons'
import ImageCarousel from '../../../Components/carousel'

export default function Bookdharmashala() {

    return (
        <div>
            <div className="flex flex-col items-center justify-center   bg-gray-100">
                <ImageCarousel />
            </div>
            {/* <PulicButtons text="नया बुकिंग" className=" bg-blue-950 text-white" url="/dashboard/dharmashalaBooking" /> */}
            <DharmashalaBookingFrom />
        </div>
    )
}
