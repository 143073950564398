
import API from "./API";
// const accessToken = sessionStorage.getItem("access_token")

// console.log(accessToken)
const token = () => {
    const accessToken = sessionStorage.getItem("access_token")
    return `Bearer ${accessToken}`;
}

export const addMatrimonyProfile = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/matrimony/addMatrimonyProfile', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getCitysOnInput = async (formData) => {
    const authorizationHeader = token();
    const response = await API.post('/cities/search', formData,
        {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            return 'regisration failed.'
        }

        )
    return response.data;
}
// member ***************
export const getMember = async (data, requestOptions) => {
    // const accessToken = token();
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/searchMemberForMemberPage', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const upDateMember = async (formData, requestOptions) => {
    // const accessToken = sessionStorage.getItem("access_token");
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    // console.log("Authorization Header:", authorizationHeader);
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.put('/updateMember', formData, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

// ***************************************


export const isUserLogin = async (apiEndPoint, formData) => {
    // const accessToken = sessionStorage.getItem("access_token");
    // console.log(accessToken) // Replace with your token retrieval logic
    const authorizationHeader = token();
    console.log('apiEndPoint, formData', apiEndPoint, formData)
    const response = await API.get(apiEndPoint, formData,
        {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        }).catch((error) => {
            return error
        }
        )
    return response ? response.data : {}
}

export const addNews = async (formData) => {
    const authorizationHeader = token();

    try {
        const response = await API.post('/addnews', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const searchNewsForNewsPage = async (data,) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/searchNewsForNewsPage', data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const shockNewNotification = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/shockNewNotifications', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
// /dharmashalBooking
export const bookDharmashala = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/dharmashalBooking', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
export const getDharmashala = async () => {

    const authorizationHeader = token();
    try {
        const response = await API.get('/getDharmashala', {
            headers: {
                'Authorization': authorizationHeader,
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
export const logout = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/logout', {}, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        sessionStorage.removeItem('access_token');
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const addAchivement = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addAchivement', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getSAchivement = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getAchivement', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
}



export const addsamajcalenderEvent = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addsamajEvent', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getSamajcalenderEvent = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getsamajEvent', {
            headers: {
                'Authorization': authorizationHeader,

            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
// /getsamajEvent


export const addSuggession = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addsuggesstion', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const addDonation = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addDonation', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getDonations = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getDonation', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const memberScanByQr = async (Data) => {

    try {
        const response = await API.post('/memberScanByQr', Data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const updateMemberStatus = async (formData) => {
    const authorizationHeader = token();

    try {
        const response = await API.put('/update-member-status', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }

}

// /memberStatusActivation


export const searchMemberForMemberByAdmimForActivation = async (data, requestOptions) => {
    // const authorizationHeader = token();
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/admin/member/activation', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const getMatrimony = async (data, requestOptions) => {
    // const accessToken = token();
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/matrimony/searchMatrimonyForMatrimonyPage', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

// laga
export const generateLaga = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/laga/generateLaga', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getLagabyMemberCode = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/laga/getLaga', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
};

export const searchMemberForPaymentLaga = async (data, requestOptions) => {
    // const authorizationHeader = token();
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/laga/admin/searchMemberForPaymentLaga', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}


export const updateLaga = async (formData) => {
    const authorizationHeader = token();

    try {
        const response = await API.put('/laga/admin/updateLaga', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }

}


//


// 