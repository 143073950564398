import React from 'react'
import femaleLogo from "../assets/final cards for mayur/female matromony/femalematriwomenimage.png"

import maleLoga from "../assets/final cards for mayur/male matrimony/male image.png"
import QR from "../assets/final cards for mayur/female matromony/femalmatriqrcode.png"
import intrested from "../assets/final cards for mayur/female matromony/femalematriinterestedbutton.png"
import call from "../assets/final cards for mayur/female matromony/femalematricallbutton.png"
import chat from "../assets/final cards for mayur/female matromony/femalematrichatbutton.png"
import viwe from "../assets/final cards for mayur/female matromony/femalematriviewprofile.png"
import report from "../assets/final cards for mayur/female matromony/femalematrireportprofile.png"

import intrestedMale from "../assets/final cards for mayur/male matrimony/interestedbutton.png"
import callMale from "../assets/final cards for mayur/male matrimony/callbutton.png"
import chatMale from "../assets/final cards for mayur/male matrimony/chatbutton.png"
import viweMale from "../assets/final cards for mayur/male matrimony/viewprofilebutton.png"
import { Domain } from '../ApiConfig'

export default function Reshtey({ rishtey }) {

    const { Qr, contactDetails,
        divorceDetails,
        educationQualification
        , extraInformation,
        familyDetail,

        kundliDetails,
        mamaDetails,

        marriedStatus,
        matrimonyCode,

        occupationDetails,
        othersDetails,
        personalInformation,
        photos,
        samajReferenceDetails

    } = rishtey;

    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender,
        heightFeet, heightInch
        , permanentCity,
        gotra

    } = personalInformation

    const { birthDateTime } = kundliDetails
    // : 
    // {birthDateTime: "2024-05-01T11:02:00.000Z", birthPlace: "shendhwa", rashiByDateOfBirth: "मेष",…}



    const {
        fatherName,
        motherName
    } = familyDetail









    return (
        <>
            <div className='w-[95vw] md:w-[350px] xl:w-[400px] shadow-2xl shadow-all-sides  rounded-3xl  ps-9 p-3 h-[100%]  bg-white flex flex-col justify-start'>
                <div className='flex justify-center '>
                    <p className='bg-blue-900 w-100 px-4 xl:px-5 rounded-xl py-[0.2rem] xl:py-1 text-[1rem]  xl:text-[1.2rem] text-[#FFF411] font-semibold'>Profile ID :  {matrimonyCode}</p>
                </div>
                <div className={`relative flex mt-2 rounded-t-xl   justify-center text-xl xl:text-[16px] font-bold items-center text-white h-[2rem] xl:h-[2.5rem] w-100 ${(gender == 'Male') ? "metrimony-card-bg-male-head" : "bg-[#EC268F]"}  `} >
                    {personalInformation?.firstName}

                    <img className=' shadow-2xl w-14 h-14 xl:w-16 xl:h-16 ring-[#FFF411] ring-2 absolute z-50 left-[-1.8rem]  xl:left-[-2rem] rounded-full' src={`${Domain + photos?.profilePhoto}`} />

                </div>

                <div className={`rounded-b-2xl   relative w-full ${(gender == 'Male') ? "metrimony-card-bg-male-body" : "metrimony-card-bg"}    flex flex-col border-dashed border-gray-500  border-b-2  border-e-2 border-s-2`}>
                    <div className='w-full flex justify-center mt-[-1rem] h-[100%]  xl:mt-[0rem] xl:pb-3'>
                        <div className='  w-[20%] mt-5 xl:ms-2  xl:mt-2 flex justify-center items-center '>
                            <img className=' w-28 h-28 xl:w-36 xl:h-36 object-cover' src={gender == 'Male' ? maleLoga : femaleLogo} />
                        </div>

                        <div className="  text-[#FFF411] flex-col text-[12px] leading-[16px] xl:leading-[20px] xl:text-[16px] justify-center w-[100%]   ">
                            <div className=' h-[100%] ms-5 text-[10px] font-bold   xl:ms-7 py-6 xl:pt-3'>
                                <div className="flex flex-row justify-between ">
                                    <p className=" w-20 xl:w-40">गोत्र</p>
                                    <p className="w-4/5 metrimony-card-colone">{gotra} </p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">उम्र</p>
                                    <p className="w-4/5 metrimony-card-colone"> {calculateAge(birthDateTime)} वर्ष</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">लंबाई</p>
                                    <p className="w-4/5 metrimony-card-colone"> {heightFeet + '.' + heightInch} feet</p>

                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वर्त्तमान शहर</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {currentCity?.cityName}</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">व्यवसाय</p>
                                    <p className="w-4/5  metrimony-card-colone"> {occupationDetails?.occupation}</p>
                                </div>
                                {/* <div className="flex flex-row justify-between">
                                    <p className="w-full">पिताजी का व्यवसाय</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {fatherName}</p>
                                </div> */}
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वैवाहिक स्थिति</p>
                                    <p className="w-4/5 metrimony-card-colone"> {marriedStatus}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=' flex justify-center items-center mt-2 w-full absolute top-[85%]'>
                        <img className=' p-1 metrimony-card-bg-bottom shadow-2xl rounded-xl w-16 h-16 absolute top-[-0.5rem] left-[2rem]  md:left-[1.5rem]   xl:left-[2rem]' src={Qr ? `${Domain + Qr}` : QR} />
                        <div className=' metrimony-card-bg-bottom flex p-2 ps-10  gap-2 justify-end rounded-2xl h-100'>
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? intrestedMale : intrested} />
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? callMale : call} />
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? chatMale : chat} />
                            <img className='h-7 w-7 xl:h-9 cursor-pointer  xl:w-9' src={gender === "Male" ? viweMale : viwe} />
                        </div>
                    </div>
                </div>
                <div className=' flex justify-end mt-7   xl:mt-2'>
                    <img className=' bg-slate-400 rounded-sm w-16 h-4 xl:w-16 xl:h-5 ' src={report} />
                </div>
            </div>
        </>
    )
}
export function calculateAge(birthDateTime) {
    const birthDate = new Date(birthDateTime);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - birthDate.getFullYear();
    let months = currentDate.getMonth() - birthDate.getMonth();

    if (months < 0) {
        years--;
        months += 12;
    }

    return `${years}.${months}`;
}
